import React from "react";

export default function ApiError({ error }) {
  let message = "Hata";
  let errors = ["Bir hata oluştu."];

  if (error.response) {
    switch (error.response.status) {
      case 404:
        message = "Kayıt bulunamadı";
        errors = [];
        break;
      case 400:
        message = "Geçersiz istek";
        errors = [];
        for (let key in error.response.data) {
          const keyErrors = error.response.data[key];
          const list = Array.isArray(keyErrors) ? keyErrors : [keyErrors];

          errors = errors.concat(
            list.map((e) => {
              return key === "non_field_errors" ? e : <span>{e}</span>;
            })
          );
        }
        break;
    }
  }

  const description = (
    <ul>
      {errors.map((error, i) => (
        <li key={i}>{error}</li>
      ))}
    </ul>
  );

  return (
    <div className="api-error">
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-red-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
            <div className="mt-2 text-sm text-red-700">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
