import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import trLocale from "moment/locale/tr";
import ApiError from "../components/ApiError";
import { Link } from "react-router-dom";

moment.locale("tr", trLocale);

const ProAnalysis = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleDelete = (row) => {
    if (window.confirm("Are you sure?")) {
      axios
        .delete(`alarm/proanalysis/${row.id}/`)
        .then((res) => window.location.reload())
        .catch((e) => setError(e));
    }
  };

  useEffect(() => {
    axios
      .get("alarm/proanalysis/")
      .then((res) => setData(res.data))
      .catch((e) => setError(e));
  }, []);

  if (!data && !error) return <div>Loading</div>;

  return (
    <>
      <div className="mb-4 flex items-center">
        <div className="font-bold text-xl flex-grow">Analiz PRO</div>
        <Link
          to="/proanalysis/create"
          className="rounded border-blue-600 text-blue-600 border py-1 px-4 mt-2"
        >
          Ekle
        </Link>
      </div>

      <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg mt-4">
        {error && <ApiError error={error} />}

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Tarih
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Gönderen
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Başlık
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                İçerik
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Vimeo URL
              </th>
              <th scope="col" className="relative px-6 py-3" />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, i) => (
                <tr className={i % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {moment(row.created_at).fromNow()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {row.user?.first_name} {row.user?.last_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {row.title}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <span className="truncate break-all">
                      {row.text.slice(0, 50)}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">
                    <span className="truncate break-all">{row.vimeo_url}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      onClick={() => handleDelete(row)}
                      className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    >
                      Sil
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProAnalysis;
