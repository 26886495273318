import axios from "axios";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import arrayMove from "array-move";
import { Redirect } from "react-router";

const DEFAULT_STOCKS = [
  { code: "", price: "" },
  { code: "", price: "" },
];

const FilePreview = ({ file }) => {
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  return (
    <div className="my-1">
      <img src={url} className="w-48" />
    </div>
  );
};

const Upload = () => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState(DEFAULT_STOCKS);
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFiles((files) => [...files, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = () => {
    let data = new FormData();
    data.append("title", title);
    data.append("text", text);
    data.append("count", files.length);
    data.append(
      "stocks",
      JSON.stringify(stocks.filter((s) => s.code.trim() !== ""))
    );

    files.forEach((f, i) => {
      data.append(`file${i}`, f);
    });

    setLoading(true);

    axios
      .post("alarm/sentiments/create/", data)
      .then((res) => setSuccess(true))
      .catch((e) => alert("Kaydedilirken bir hata oluştu."))
      .finally(() => setLoading(false));
  };

  const handleStockInputChange = (index, key, value) => {
    let newStocks = [...stocks];
    newStocks[index] = { ...newStocks[index], [key]: value };

    if (index === stocks.length - 1) {
      newStocks.push({ code: "", price: "" });
    }

    setStocks(newStocks);
  };

  const handleSortEnd = (oldIndex, newIndex) =>
    setFiles(arrayMove(files, oldIndex, newIndex));

  if (success) return <Redirect to="/sentiments" />;

  return (
    <div>
      <div className="mb-4">
        <label>Başlık</label>
        <input
          required
          type="text"
          className="form-input w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label>İçerik</label>
        <textarea
          required
          className="form-textarea w-full h-64"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <table className="min-w-full divide-y divide-gray-200 border-2">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Hisse
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Son Fiyat
              </th>
            </tr>
          </thead>
          <tbody>
            {stocks.map((row, i) => (
              <tr className={i % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                <td className="px-3 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                  <input
                    type="text"
                    value={row.code}
                    onChange={(e) =>
                      handleStockInputChange(i, "code", e.target.value)
                    }
                    className="form-input w-full p-1"
                  />
                </td>
                <td className="px-3 py-1 whitespace-nowrap text-sm text-gray-900">
                  <input
                    type="text"
                    value={row.price}
                    onChange={(e) =>
                      handleStockInputChange(i, "price", e.target.value)
                    }
                    className="form-input w-full p-1"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="rounded-md border-2 border-dashed bg-gray-200 border-gray-400 text-center cursor-pointer">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="p-6">Dosyaları buraya bırakabilirsiniz...</p>
          ) : (
            <p className="p-6">
              Dosyaları sürükleyip buraya yapıştırabilirsiniz veya tıklayarak
              yükleme yapabilirsiniz.
            </p>
          )}
        </div>
      </div>

      {files.length > 0 && (
        <>
          <div className="font-medium my-2">Yüklenen dosyalar</div>
          {files.map((f, i) => (
            <div key={`${i}`} className="flex flex-row items-center">
              <FilePreview file={f} />
              <div className="pl-3 font-medium text-sm">
                {i > 0 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleSortEnd(i, i - 1)}
                  >
                    Yukarı
                  </div>
                )}
                {i < files.length - 1 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => handleSortEnd(i, i - 1)}
                  >
                    Aşağı
                  </div>
                )}
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    setFiles([...files.slice(0, i), ...files.slice(i + 1)])
                  }
                >
                  Sil
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="rounded bg-blue-600 text-white py-1 px-4 mt-4"
      >
        {loading ? "Kaydediliyor..." : "Kaydet"}
      </button>
    </div>
  );
};

const SentimentsCreate = () => {
  return (
    <div>
      <div className="mb-4 pt-1.5 flex items-center">
        <div className="font-bold text-xl flex-grow">
          Sentiment Algo Yeni Kayıt
        </div>
      </div>

      <div className="bg-white rounded-md shadow-md p-4 mb-4">
        <Upload />
      </div>
    </div>
  );
};

export default SentimentsCreate;
