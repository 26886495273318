export const WHITELABELS = {
  info: {
    title: "İNFO YATIRIM",
    wl: "info",
  },
  ak: {
    title: "AK YATIRIM",
    wl: "ak",
  },
  osmanli: {
    title: "OSMANLI MENKUL",
    wl: "osmanli",
  },
  alb: {
    title: "ALB YATIRIM",
    wl: "alb",
  },
  ideal: {
    title: "iDEAL DATA",
    wl: "ideal",
  },
  colendi: {
    title: "Colendi Menkul",
    wl: "colendi",
  },
};

export const API_URL =
  process.env["REACT_APP_API_URL"] || "https://mobile-api.idealdata.com.tr";
