import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const validateUser = (token, onError) => (dispatch) => {
  const authHeader = `Bearer ${token}`;

  axios
    .get("auth/check/", { headers: { Authorization: authHeader } })
    .then((res) => {
      if (!res.data.user.wl) {
        throw new Error("Erişiminiz bulunmamaktadır.");
      }

      localStorage.setItem("APP_AUTH_TOKEN", token);
      axios.defaults.headers["Authorization"] = authHeader;
      dispatch(loginSuccess(res.data));
    })
    .catch((e) => {
      delete axios.defaults.headers["Authorizations"];
      dispatch(loginFail());
      if (onError) onError(e);
    });
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    verified: false,
  },
  reducers: {
    loginSuccess(state, action) {
      state.verified = true;
      state.user = action.payload.user;
    },
    loginFail(state) {
      state.verified = true;
      state.user = null;
    },
    logout(state) {
      delete axios.defaults.headers["Authorizations"];
      localStorage.removeItem("APP_AUTH_TOKEN");
      state.user = null;
    },
  },
});

export const { loginSuccess, loginFail, logout } = authSlice.actions;

export default authSlice.reducer;
