import axios from "axios";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import trLocale from "moment/locale/tr";
import Modal, { Header } from "../components/Modal";

moment.locale("tr", trLocale);

const Logs = () => {
  const [data, setData] = useState(null);
  const [detail, setDetail] = useState(null);

  useEffect(() => {
    axios
      .get("alarm/logs/")
      .then((res) => setData(res.data))
      .catch((e) => alert("Bir hata oluştu."));
  }, []);

  return (
    <div
      className="shadow rounded-md"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="w-full h-full flex bg-white">
        <div className="w-1/6 h-full overflow-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody>
              {data &&
                data.map((row, i) => (
                  <tr
                    onClick={() => setDetail(row)}
                    className={
                      (detail === row
                        ? "bg-gray-900 text-white"
                        : i % 2 === 0
                        ? "bg-gray-50 hover:bg-gray-100"
                        : "bg-white hover:bg-gray-100") +
                      " cursor-pointer border-b"
                    }
                  >
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <div className="font-bold text-xs">
                        {row.ideal_username}
                      </div>
                      <div className="opacity-50 text-xs">
                        {moment(row.created_at).fromNow()}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="w-5/6 border-l border-gray-300">
          <pre className="whitespace-pre-wrap text-left text-xs font-medium text-gray-900 uppercase tracking-wider bg-white overflow-y-auto h-full">
            {detail?.data?.map &&
              detail.data.map((r) => (
                <div className="border-b border-gray-300 py-3 px-6">
                  {r.replace(/===Optimus/g, "").replace("===", "")}
                </div>
              ))}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Logs;
