import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./auth";
import thunkMiddleware from "redux-thunk";

const rootReducer = combineReducers({
  auth,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware, ...getDefaultMiddleware()],
  devTools: true,
});

export default store;
