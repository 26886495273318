import axios from "axios";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import trLocale from "moment/locale/tr";

moment.locale("tr", trLocale);

const Results = ({ data, onRepeat }) => (
  <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg mt-4">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Tarih
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Gönderen
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Hedef
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Mesaj
          </th>
          <th scope="col" className="relative px-6 py-3" />
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, i) => (
            <tr className={i % 2 === 0 ? "bg-gray-50" : "bg-white"}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {moment(row.created_at).fromNow()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.sender.first_name} {row.sender.last_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                {row.target_channel}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900">
                <span className="truncate break-all">{row.message}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a
                  onClick={() => onRepeat(row)}
                  className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                >
                  Düzenleyip tekrar gönder
                </a>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

const FcmTopic = () => {
  const [data, setData] = useState(null);
  const [message, setMessage] = useState("");
  const [target, setTarget] = useState("all_users"); // all, file, single
  const [targetChannel, setTargetChannel] = useState("");
  const [loading, setLoading] = useState(false);
  const textareaRef = useRef();

  useEffect(() => {
    axios
      .get("alarm/fcmpushs/")
      .then((res) => setData(res.data))
      .catch((e) => alert("Bir hata oluştu."));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!message || (!target === "single" && !targetChannel)) {
      return;
    }

    if (!window.confirm("Emin misiniz?")) {
      return;
    }

    setLoading(true);
    
    axios
      .post("alarm/fcmpushs/", {
        target_channel: target == "all_users" ? "all_users" : targetChannel,
        sender: "",
        message: message,
      })
      .then((res) => {
        setData([res.data, ...data]);

        // Reset
        setMessage("");
        setTarget(target);
        setTargetChannel("");
      })
      .catch((e) => alert("Bir hata oluştu."))
      .finally(() => setLoading(false));
      
  };

  const handleTargetChange = (e) => setTarget(e.target.value);

  const handleRepeat = (fcmtopic) => {
    setMessage(fcmtopic.message);
    setTarget(fcmtopic.target_channel === null ? "all_users" : "single");
    setTargetChannel(fcmtopic.target_channel);

    textareaRef.current.focus();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded shadow pb-4 px-4 pt-1"
      >
        <div className="mb-2 flex items-center h-12">
          <label>
            <input
              checked={target === "all_users"}
              type="radio"
              className="-mt-0.5"
              name="target"
              disabled={loading}
              value="all_users"
              onChange={handleTargetChange}
            />{" "}
            Herkese
          </label>
          <label>
            <input
              checked={target === "single"}
              type="radio"
              className="-mt-0.5 ml-4"
              name="target"
              disabled={loading}
              value="single"
              onChange={handleTargetChange}
            />{" "}
            Kanal
          </label>{" "}
          {target === "single" && (
            <input
              type="text"
              className="form-input h-8 ml-4
              "
              placeholder="Topic"
              autoFocus
              value={targetChannel}
              onChange={(e) => setTargetChannel(e.target.value)}
            />
          )}
        </div>

        <textarea
          className="form-textarea w-full h-100"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={loading}
          ref={textareaRef}
        />
        <button
          disabled={loading}
          className="rounded bg-blue-600 text-white py-1 px-4 mt-2"
        >
          {loading ? "Gönderiliyor..." : "Gönder"}
        </button>
      </form>

      <Results onRepeat={handleRepeat} data={data} />
    </>
  );
};

export default FcmTopic;
