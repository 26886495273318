import axios from "axios";
import React, { useEffect, useState } from "react";
import ApiError from "../components/ApiError";
import * as XLSX from "xlsx";

const Analytics = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [csvExportVersion, setCsvExportVersion] = useState(null);

  // Individual user information
  const [userLoading, setUserLoading] = useState(false);
  const [userResult, setUserResult] = useState(null);
  const [user, setUser] = useState("");

  useEffect(() => {
    axios
      .get("alarm/analytics/?report=version_breakdown")
      .then((res) => setData(res.data.data))
      .catch((e) => setError(e));
  }, []);

  const handleCsvExport = (row) => {
    if (csvExportVersion) {
      alert(
        "Bir versiyon için CSV export işlemi yapılmaktadır. Lütfen bekleyiniz."
      );
      return;
    }

    setCsvExportVersion(row.version);

    axios
      .get(`alarm/analytics/?report=version_users&id=${row.version}`)
      .then((res) => {
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          XLSX.utils.aoa_to_sheet(res.data.data.map((r) => [r]))
        );
        XLSX.writeFile(workbook, `${row.version}.csv`);
      })
      .catch((e) => setError(e))
      .finally(() => setCsvExportVersion(null));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user) {
      return;
    }

    if (userLoading) {
      alert("Bir hesap için versiyon bilgisi alınmaktadır. Lütfen bekleyiniz.");
      return;
    }

    setUserResult(null);
    setUserLoading(true);

    axios
      .get(`alarm/analytics/?report=version_user&id=${user}`)
      .then((res) => {
        setUserResult(res.data.data);
      })
      .catch((e) => setError(e))
      .finally(() => setUserLoading(null));
  };

  if (!data && !error) return <div>Yükleniyor</div>;

  return (
    <>
      <div className="mb-4 flex items-center">
        <div className="font-bold text-xl flex-grow">Versiyon Takibi</div>
      </div>

      <form
        onSubmit={handleSubmit}
        className="shadow overflow-hidden border-b border-gray-200 rounded-lg bg-white mt-4 p-4"
      >
        <input
          type="text"
          className="form-input h-8"
          placeholder="Hesap no"
          autoFocus
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
        <button
          disabled={userLoading}
          className="rounded bg-blue-600 text-white py-1 px-4 rounded-l-none"
        >
          {userLoading ? "Yükleniyor..." : "Versiyonu Göster"}
        </button>
        {!!userResult && (
          <span className="bg-green-50 text-green-600 ml-2 inline-block border rounded px-2 text-sm">
            {userResult}
          </span>
        )}
      </form>

      <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg mt-4">
        {error && <ApiError error={error} />}

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Versiyon
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Hesap sayısı
              </th>
              <th scope="col" className="relative px-6 py-3 w-48" />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, i) => (
                <tr
                  key={row.version}
                  className={i % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {row.version}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {row.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <a
                      onClick={() => handleCsvExport(row)}
                      className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                    >
                      {csvExportVersion === row.version
                        ? "Hazırlanıyor..."
                        : "CSV İndir"}
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Analytics;
