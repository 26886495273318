import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router";

const Form = () => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    let data = new FormData();
    data.append("title", title);
    data.append("file", file);

    setLoading(true);

    axios
      .post("alarm/hisseradari/create/", data)
      .then((res) => setSuccess(true))
      .catch((e) => alert("Kaydedilirken bir hata oluştu."))
      .finally(() => setLoading(false));
  };

  if (success) return <Redirect to="/hisseradari" />;

  return (
    <div>
      <div className="mb-4">
        <label>Başlık</label>
        <input
          required
          type="text"
          className="form-input w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label>PDF</label>
        <input
          required
          type="file"
          className="form-input w-full"
          accept="application/pdf"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>

      <button
        onClick={handleSubmit}
        disabled={loading}
        className="rounded bg-blue-600 text-white py-1 px-4 mt-4"
      >
        {loading ? "Kaydediliyor..." : "Kaydet"}
      </button>
    </div>
  );
};

const HisseRadariCreate = () => {
  return (
    <div>
      <div className="mb-4 pt-1.5 flex items-center">
        <div className="font-bold text-xl flex-grow">
          Hisse Radarı Yeni Kayıt
        </div>
      </div>

      <div className="bg-white rounded-md shadow-md p-4 mb-4">
        <Form />
      </div>
    </div>
  );
};

export default HisseRadariCreate;
